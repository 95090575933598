import { useEffect, useState } from "react";
import FooterHeaderWrapper from "../layout/footerHeaderWrapper";
import { Link, useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";


const BookingList = () => {
    const Navigate = useNavigate();
    let bookingList = useSelector((state) => state.booking.getPackageBookingList.data);
    let resortBookingList = useSelector((state) => state.resort.resortBookingList?.data);
    console.log('booking list ---- ', bookingList, resortBookingList);
    const [activeTab, setActiveTab] = useState('Resorts');
    const [confirmedBookingModal, setConfirmedBookingModel] = useState(false);
    const [cancelBookingModal, setCancelBookingModal] = useState(false);
    const [confirmCancelBookingModal, setConfirmedCancelBookingModel] = useState(false);
    const [cancellingReason, setCancellingReason] = useState("");
    const [cancellingIds, setCancellingIds] = useState({
        id: 0,
        resort_id: 0
    });
    const [ratingModal, setRatingModal] = useState(false);
    const [rating, setRating] = useState(0);  // State for rating
    const [message, setMessage] = useState('');  // State for feedback message
    const [resortId, setResortId] = useState(0);  // State for feedback message

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        actions.getPackageBookingAction();
        actions.getResortBookingListAction(resortSearchData);
    }, [])

    let user_details = JSON.parse(localStorage.getItem('user_details'));

    //
    const confirmBookingModal = () => {
        setConfirmedBookingModel(false);
        setCancelBookingModal(true);
    }

    const confirmCancelBooking = () => {
        setCancelBookingModal(false);
        setConfirmedCancelBookingModel(true);
        let data = {
            id: cancellingIds?.id,
            resort_id: cancellingIds?.resort_id,
            cancellingReason
        }
        actions.cancelBookingAction(data)
    }


    //Rating manage
    const handleStarClick = (rate) => {
        setRating(rate);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async () => {
        let data = {
            resort_id: resortId,
            star_rating: rating,
            comments: message
        }
        await actions.postResortRatingAction(data);
        setRating(0);
        setMessage('');
        setRatingModal(false);
        toast.success("Your rating is successfully saved");
    };

    //handle search and filter
    const [resortSearchData, setResortSearchData] = useState();
    const handleResortSearch = (e) => {
        let { name, value } = e.target;
        setResortSearchData({ ...resortSearchData, [name]: value });
    }

    return (
        <FooterHeaderWrapper>

            <div className="DetailsGrey">
                <div className="HotelBreadcumb">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Booking List</li>
                    </ul>
                </div>
                <div className="BookingTabs">
                    <h1>My Bookings</h1>

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <Link to="#Resorts" className={`nav-link ${activeTab === 'Resorts' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Resorts')} data-toggle="tab">Resorts</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#Packages" className={`nav-link ${activeTab === 'Packages' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Packages')} data-toggle="tab">Packages</Link>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className={`tab-pane fade ${activeTab === 'Resorts' ? 'show active' : ''}`} id="Resorts">

                            <div className="Filter">
                                <div className="form-group Search">
                                    <h6>Booking Reference ID</h6>
                                    <input type="text" onClick={handleResortSearch} className="form-control" placeholder="E.g. 6512-SD454-22" />
                                    <span><i className="fa-solid fa-magnifying-glass"></i></span>
                                </div>
                                <div className="form-group">
                                    <h6>From</h6>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <h6>To</h6>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group FilterSelect">
                                    <select className="form-control">
                                        <option selected>All</option>
                                        <option>Confirmed</option>
                                        <option>Completed</option>
                                        <option>Cancelled</option>
                                        <option>pppppp</option>
                                    </select>
                                </div>
                            </div>
                            {
                                resortBookingList && resortBookingList?.map((val, index) => {
                                    return (
                                        <>
                                            <div key={index} className="BookingDetailsBox">
                                                <div className="BookingHeader">
                                                    <div className="SmallBox">
                                                        <p>Booking Reference ID</p>
                                                        <h6>{val?.id}</h6>
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>Status</p>
                                                        {val?.status == 1 ?
                                                            <>
                                                                {val?.booking_end_date <= moment(new Date()).format("YYYY-MM-DD") ?
                                                                    <h6 className="Confirmed">Completed <span><img src={require("../../assets/images/confirmed.png")} alt="confirm" /></span></h6>
                                                                    :
                                                                    <h6 className="Confirmed">Confirmed <span><img alt="booking-img" src={require("../../assets/images/confirmed.png")} /></span></h6>
                                                                }
                                                            </>
                                                            :
                                                            <h6 className="Cancelled">Cancelled <span><img alt="booking-img" src={require("../../assets/images/cancelled.png")} /></span></h6>
                                                        }
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>From To</p>
                                                        <h6>
                                                            {moment(val?.booking_start_date).format('DD/MM/YYYY')} - {moment(val?.booking_end_date).format('DD/MM/YYYY')}
                                                        </h6>
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>Customer Name</p>
                                                        <h6>{user_details?.first_name} {user_details?.last_name}</h6>
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>Booking Date</p>
                                                        <h6>{moment(val?.created_at).format('DD/MM/YYYY')}</h6>
                                                    </div>
                                                </div>
                                                <div className="BookingContent">
                                                    <figure>
                                                        <img src={val?.images ? val?.images : require("../../assets/images/booking-1.png")} alt="img" />
                                                        {/* <img src={require("../../assets/images/booking-1.png")} alt="img" />
                                                        <div className="Overlay">
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-1.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-2.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-3.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-4.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-5.png")} /></span>
                                                        </div>
                                                        <figcaption>9 Images</figcaption> */}
                                                    </figure>
                                                    <div className="BookingCenterMain">
                                                        <div className="BookingCenter">
                                                            <span style={{ cursor: 'pointer' }} onClick={() => Navigate(`/resort-booking-confirmed/${val?.id}`)}><h4>{val?.resort_name}</h4></span>
                                                            <p><i className="fa-solid fa-location-dot"></i>{val?.resort_address}, {val?.resort_pincode}</p>
                                                            <ul>
                                                                <li><span><img alt="booking-img" src={require("../../assets/images/check-in.png")} /></span>Check in after: {moment(val?.check_in_from, 'HH:mm:ss').format('hh:mm:ss A')}</li>
                                                                <li><span><img alt="booking-img" src={require("../../assets/images/check-out.png")} /></span>Check out before: {moment(val?.check_out_from, 'HH:mm:ss').format('hh:mm:ss A')}</li>
                                                                <li><span><img alt="days" src={require("../../assets/images/nights-days.png")} /></span>{val?.nights} Nights {parseInt(val?.nights) + 1} Days</li>
                                                                <li><span><img alt="booking-img" src={require("../../assets/images/carbon_information.png")} /></span>Cancellation Policy</li>
                                                            </ul>
                                                        </div>
                                                        <div className="BookingBottom">
                                                            {/* <p>1 x Bedroom Water Retreat (with Slide) - 2 Adults</p>
                                                            <p>2 x Bedroom Water Retreat - 3 Adults</p> */}
                                                            <p>{val?.room_type} - {val?.rooms_adults} {val?.children ? ', ' + val?.children + " children" : ''} {val?.infant ? ', ' + val?.infant + " infant" : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="BookingLast">
                                                        <div>
                                                            {val?.status == 1 ?
                                                                <>
                                                                    {val?.booking_end_date <= moment(new Date()).format("YYYY-MM-DD") ?
                                                                        <button type="button" className="SendEnquiry" onClick={() => { setRatingModal(true); setResortId(val?.resort_id) }}>Rate Property</button> :
                                                                        <button type="button" className="SendEnquiry" onClick={() => { setConfirmedBookingModel(true); setCancellingIds({ ...cancellingIds, id: val?.id, resort_id: val?.resort_id }) }}>Cancel Booking</button>
                                                                    }
                                                                </>
                                                                :
                                                                ""
                                                            }

                                                            <button type="button" className="BookBtn" >Invoice</button>
                                                        </div>
                                                        <div className="text-right">
                                                            <h4>${val?.payment_amount}</h4>
                                                            <p>Includes taxes and fees</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }


                            {/* <div className="Paginations">
                                <ul>
                                    <li><Link to="#" className="Grey"><i className="fa-solid fa-chevron-left"></i></Link></li>
                                    <li><Link to="#">2</Link></li>
                                    <li><Link to="#">3</Link></li>
                                    <li><Link to="#">4</Link></li>
                                    <li><Link to="#">5</Link></li>
                                    <li><Link to="#" className="Black"><i className="fa-solid fa-chevron-right"></i></Link></li>
                                </ul>
                            </div> */}
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'Packages' ? 'show active' : ''}`} id="Packages">

                            <div className="Filter">
                                <div className="form-group Search">
                                    <h6>Booking Reference ID</h6>
                                    <input type="text" className="form-control" placeholder="E.g. 6512-SD454-22" />
                                    <span><i className="fa-solid fa-magnifying-glass"></i></span>
                                </div>
                                <div className="form-group">
                                    <h6>From</h6>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <h6>To</h6>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="form-group Select">
                                    <select className="form-control">
                                        <option selected>All</option>
                                        <option>Confirmed</option>
                                        <option>Completed</option>
                                        <option>Cancelled</option>
                                    </select>
                                </div>
                            </div>
                            {
                                bookingList?.map((val, index) => {
                                    return (
                                        <>
                                            <div key={index} className="BookingDetailsBox">
                                                <div className="BookingHeader">
                                                    <div className="SmallBox">
                                                        <p>Booking Reference ID</p>
                                                        <h6>{val?.id}</h6>
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>Status</p>
                                                        {/* {
                                                            val?.status == 1 ?
                                                                <h6 className="Confirmed">Confirmed <span><img alt="booking-img" src={require("../../assets/images/confirmed.png")} /></span></h6>
                                                                :
                                                                <h6 className="Cancelled">Cancelled <span><img alt="booking-img" src={require("../../assets/images/cancelled.png")} /></span></h6>
                                                        } */}

                                                        {val?.status == 1 ?
                                                            <>
                                                                {val?.booking_end_date <= moment(new Date()).format("YYYY-MM-DD") ?
                                                                    <h6 className="Confirmed">Completed <span><img src={require("../../assets/images/confirmed.png")} alt="confirm" /></span></h6> :
                                                                    <h6 className="Confirmed">Confirmed <span><img alt="booking-img" src={require("../../assets/images/confirmed.png")} /></span></h6>
                                                                }
                                                            </>
                                                            :
                                                            <h6 className="Cancelled">Cancelled <span><img alt="booking-img" src={require("../../assets/images/cancelled.png")} /></span></h6>
                                                        }

                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>From To</p>
                                                        <h6>
                                                            {moment(val?.booking_start_date).format('DD/MM/YYYY')} - {moment(val?.booking_end_date).format('DD/MM/YYYY')}
                                                        </h6>
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>Customer Name</p>
                                                        <h6>{user_details?.first_name} {user_details?.last_name}</h6>
                                                    </div>
                                                    <div className="SmallBox">
                                                        <p>Booking Date</p>
                                                        <h6>{moment(val?.created_at).format('DD/MM/YYYY')}</h6>
                                                    </div>
                                                </div>
                                                <div className="BookingContent">
                                                    <figure>
                                                        <img src={val?.images ? val?.images : require("../../assets/images/booking-1.png")} alt="img" />
                                                        {/* <img src={require("../../assets/images/booking-1.png")} alt="img" />
                                                        <div className="Overlay">
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-1.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-2.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-3.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-4.png")} /></span>
                                                            <span><img alt="booking-img" src={require("../../assets/images/small-5.png")} /></span>
                                                        </div>
                                                        <figcaption>9 Images</figcaption> */}
                                                    </figure>
                                                    <div className="BookingCenterMain">
                                                        <div className="BookingCenter">
                                                            <Link to="/resort-booking-confirmed"><h4>{val?.package_name}</h4></Link>
                                                            <p><i className="fa-solid fa-location-dot"></i>{val?.address}, {val?.pincode}</p>
                                                            <ul>
                                                                <li><span><img alt="booking-img" src={require("../../assets/images/check-in.png")} /></span>Check in after: {moment(val?.check_in_from, 'HH:mm:ss').format('hh:mm:ss A')}</li>
                                                                <li><span><img alt="booking-img" src={require("../../assets/images/check-out.png")} /></span>Check out before: {moment(val?.check_out_from, 'HH:mm:ss').format('hh:mm:ss A')}</li>
                                                                <li><span><img alt="days" src={require("../../assets/images/nights-days.png")} /></span>{val?.nights} Nights {parseInt(val?.nights) + 1} Days</li>
                                                                <li><span><img alt="booking-img" src={require("../../assets/images/carbon_information.png")} /></span>Cancellation Policy</li>
                                                            </ul>
                                                        </div>
                                                        <div className="BookingBottom">
                                                            {/* <p>1 x Bedroom Water Retreat (with Slide) - 2 Adults</p>
                                                            <p>2 x Bedroom Water Retreat - 3 Adults</p> */}
                                                            <p>{val?.room_type} - {val?.rooms_adults}</p>
                                                        </div>
                                                    </div>
                                                    <div className="BookingLast">
                                                        <div>
                                                            {val?.status == 1 ?
                                                                <>
                                                                    {val?.booking_end_date <= moment(new Date()).format("YYYY-MM-DD") ?
                                                                        <button type="button" className="SendEnquiry" onClick={() => { setRatingModal(true); setResortId(val?.package_id) }}>Rate Property</button> :
                                                                        <button type="button" className="SendEnquiry" onClick={() => { setConfirmedBookingModel(true); setCancellingIds({ ...cancellingIds, id: val?.id, resort_id: val?.resort_id }) }}>Cancel Booking</button>
                                                                    }
                                                                </>
                                                                :
                                                                ""
                                                            }
                                                            {/* <span type="button" className="SendEnquiry" data-toggle="modal" data-target="#BookingConfirmedModal" >Cancel Booking</span> */}
                                                            <button type="button" className="BookBtn">Invoice</button>
                                                        </div>
                                                        <div className="text-right">
                                                            <h4>${val?.payment_amount}</h4>
                                                            <p>Includes taxes and fees</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }

                            sad
                            <div className="Paginations">
                                <ul>
                                    <li><Link to="#" className="Grey"><i className="fa-solid fa-chevron-left"></i></Link></li>
                                    <li><Link to="#">2</Link></li>
                                    <li><Link to="#">3</Link></li>
                                    <li><Link to="#">4</Link></li>
                                    <li><Link to="#">5</Link></li>
                                    <li><Link to="#" className="Black"><i className="fa-solid fa-chevron-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modals */}
            <Modal show={confirmedBookingModal} className="ModalBox">
                <Modal.Body>
                    <div className="FormArea Congratulatios">
                        <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setConfirmedBookingModel(false)}>×</Link>
                        <form>
                            <div className="BookingConfirmedBox">
                                <div className="BookingSmall">
                                    <h6><span><img src={require('../../assets/images/confirm.png')} alt="booking-img" /></span>Booking Confirmed</h6>
                                    <p><span>Bookig ID:</span> #RT213Q</p>
                                </div>
                                <div className="form-group">
                                    <h6>Select Reason Why do you want to cancel the booking</h6>
                                    <li>
                                        <label className="Radio"> Change in Plans
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Change in Plans")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Financial Constraints
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Financial Constraints")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Better Offer or Alternative
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Better Offer or Alternative")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Unforeseen Circumstances
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Unforeseen Circumstances")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Issue with the Hotel
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Financial Constraints")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Flight Cancellation or Delay
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Flight Cancellation or Delay")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Visa or Travel Document Issues
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Visa or Travel Document Issues")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Hotel Details Did Not Match
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Hotel Details Did Not Match")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Personal Emergency
                                            <input type="radio" name="Cuisine" onChange={() => setCancellingReason("Personal Emergency")} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="Radio"> Other
                                            <input type="radio" name="Cuisine" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <textarea rows="5" className="form-control" placeholder="Write here" onChange={(e) => setCancellingReason(e.target.value)}></textarea>
                                    <Link to="#" className="FormButton FolderPermissionId" onClick={confirmBookingModal}>Cancel Booking</Link>

                                </div>
                            </div>

                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={cancelBookingModal} className="ModalBox">
                <Modal.Body>
                    <div className="FormArea Congratulatios">
                        <Link href="#" className="CloseModal" data-dismiss="modal" onClick={() => setCancelBookingModal(false)}>×</Link>
                        <form>
                            <div className="CancelBookingArea">
                                <figure><img src={require("../../assets/images/cancel.png")} alt="booking-img" /></figure>
                                <h2>Are you sure want to cancel?</h2>
                                <h6>Your Booking ID <span>#723097557</span></h6>
                                <p>Total Refund Amount: <span>$120</span></p>
                                <div className="Button">
                                    <button type="button" className="OutlineBtn" onClick={confirmCancelBooking}>Yes</button>
                                    <button type="button" className="FillBtn">No</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={confirmCancelBookingModal} className="ModalBox">
                <Modal.Body>
                    <div className="FormArea Congratulatios">
                        <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setConfirmedCancelBookingModel(false)}>×</Link>
                        <form>
                            <div className="CancelBookingArea">
                                <figure><img src={require("../../assets/images/succesful.png")} alt="booking-img" /></figure>
                                <h2>Booking Cancelled Successfully</h2>
                                <p>Your Booking is cancelled successfully. Your Refund <span>$120</span> is processed & It will be credited within 3 to 5 days.</p>
                                <div className="Button">
                                    <Link to="/booking-list" className="FillBtn" onClick={() => { setConfirmedCancelBookingModel(false); actions.getResortBookingListAction(); }}>Go to My Bookings</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="modal fade SmallModal" id="LogOutModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="FormArea Congratulatios">
                            <Link to="#" className="CloseModal" data-dismiss="modal">×</Link>
                            <form>
                                <div className="CancelBookingArea">
                                    <figure><img src="assets/images/logout.png" alt="logout" /></figure>
                                    <h2>Are you sure want to logout?</h2>
                                    <div className="Button">
                                        <button type="button" className="OutlineBtn">Yes</button>
                                        <button type="button" className="FillBtn">No</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={ratingModal} className="ModalBox">
                <Modal.Body>
                    <div className="FormArea Congratulatios">
                        <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setRatingModal(false)}>×</Link>
                        <form>
                            <div className="CancelBookingArea">
                                <h2>How was your experience?</h2>
                                <p>Your feedback will help us improve our <br /> services and your experience better.</p>
                                <div className="RatingStar">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <i
                                            key={star}
                                            className={`fa-solid fa-star ${star <= rating ? 'selected' : ''}`}
                                            onClick={() => handleStarClick(star)}
                                            style={{ cursor: 'pointer', color: star <= rating ? 'gold' : 'grey' }}
                                        ></i>
                                    ))}
                                </div>
                                <div className="text-center">
                                    {rating !== 0 &&
                                        <span className="FeedBack Green">
                                            {rating === 5 ? 'Excellent' : rating === 4 ? 'Very Good' : rating === 3 ? 'Good' : rating === 2 ? 'Fair' : rating === 1 ? 'Poor' : ''}
                                        </span>}
                                </div>
                                <div className="form-group">
                                    <h6>Say Something :</h6>
                                    <textarea
                                        rows="4"
                                        className="form-control"
                                        placeholder="Write here"
                                        value={message}
                                        onChange={handleMessageChange}
                                    ></textarea>
                                </div>
                                <div className="Button">
                                    <button
                                        type="button"
                                        className="FillBtn"
                                        onClick={handleSubmit}
                                    >
                                        Submit Review
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

        </FooterHeaderWrapper>
    )
}

export default BookingList;